





























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Listing } from 'client-website-ts-library/types/Listing';
import { Profile } from 'client-website-ts-library/types';

@Component
export default class ListingCard extends Vue {
  @Prop()
  private readonly listing!: Listing;

  @Prop({ default: '' })
  private readonly primaryAgentId!: string;

  get isNew(): boolean {
    const newThreshold = (new Date().getTime()) - 86400 * 7 * 1000;

    return new Date(this.listing.CreateDate).getTime() >= newThreshold;
  }

  get primaryAgent(): Profile {
    if (this.primaryAgentId != null && this.listing.Agents.find((agent) => agent.Id === this.primaryAgentId)!) return this.listing.Agents.find((agent) => agent.Id === this.primaryAgentId)!;

    return this.listing.Agents[0]!;
  }

  get formattedAddressLine1(): string {
    const parts = [];

    if (this.listing.Address.UnitNumber) {
      parts.push(`${this.listing.Address.UnitNumber}/${this.listing.Address.StreetNumber}`);
    } else {
      parts.push(this.listing.Address.StreetNumber);
    }

    parts.push(this.listing.Address.Address);

    return parts.join(' ');
  }

  private get dayOfMonthSuffix(): string {
    const dayOfMoth = this.listing.Auction.Date.DayOfMonth;

    if (dayOfMoth > 3 && dayOfMoth < 21) return `${dayOfMoth}th`;
    switch (dayOfMoth % 10) {
      case 1: return `${dayOfMoth}st`;
      case 2: return `${dayOfMoth}nd`;
      case 3: return `${dayOfMoth}rd`;
      default: return `${dayOfMoth}th`;
    }
  }
}
